<template>
  <div class="data-table mb-4">
    <!-- Error -->
    <error v-if="showError" />

    <!-- Pagination -->
    <pagination
      v-model="page"
      :total-rows="total"
      :per-page="limit"
      :recordCount="data.length"
      :paginate="paginationTop"
      :showTotal="showTotal"
      v-if="paginationTop"
    />

    <!-- Filters -->
    <div :class="`filters mb-1`">
      <!-- Search -->
      <adt-filter v-if="showSearch" :label="$t('search')" align-end>
        <div class="d-flex">
          <form class="w-100" @submit.prevent="get">
            <b-input
              v-model="search"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm"
            ></b-input>
          </form>
          <ebp-button size="sm" @click="get" class="ml-1">
            <i class="bx bx-search"></i>
          </ebp-button>
        </div>
      </adt-filter>
    </div>

    <!-- Table -->
    <div class="table-responsive">
      <b-table
        class="books-list-table table-centered"
        borderless
        :items="data"
        :fields="fields"
        :current-page="page"
        :busy="globalLoading || loading"
        show-empty
        :empty-text="$t(noItemsText)"
        @sort-changed="get"
      >
        <!-- Inline editing -->
        <template
          v-for="(field, i) in editableFields"
          v-slot:[`cell(${field.key})`]="{ item, index }"
        >
          <component
            :is="editInput(field)"
            :key="i"
            :value="editedItem[field.key]"
            class="inline-editing-input"
            size="sm"
            v-if="editedItemIdx === index"
            @input="handleEdit(field.key, $event)"
            v-bind="field.attrs"
          />
          <template v-else>{{ item[field.key] }}</template>
        </template>

        <!-- Propagate slots -->
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="slotData"
          ><slot :name="name" v-bind="slotData"
        /></template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <spinner />
          </div>
        </template>

        <!-- Actions -->
        <template #cell(actions)="{ item, index }">
          <slot
            name="actions"
            :item="item"
            :index="index"
            v-if="!edited && editedItem === null"
          />
          <!-- Edit actions -->
          <template v-if="inlineEditing">
            <ebp-button
              @click="enableEdit(index, item)"
              variant="primary"
              size="sm"
              class="ml-xl-1"
            >
              <i
                :class="
                  `mdi mdi-${
                    editedItemIdx === index
                      ? edited
                        ? 'floppy'
                        : 'close'
                      : 'pencil'
                  }`
                "
              ></i>
            </ebp-button>
          </template>
        </template>
      </b-table>
    </div>

    <!-- Pagination -->
    <pagination
      v-model="page"
      :total-rows="total"
      :per-page="limit"
      :recordCount="data.length"
      :paginate="paginationBottom"
      :showTotal="showTotal"
      v-if="paginationBottom"
    />
  </div>
</template>

<script>
import { cloneDeep, isEqual, findIndex } from "lodash-es";
import { kebab } from "case";
import AdtFilter from "@/components/core/adt-filter";
import Pagination from "@/components/core/Pagination";

export default {
  name: "data-table",
  components: {
    AdtFilter,
    Pagination
  },
  props: {
    noItemsText: {
      type: String,
      default: "no-items"
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    action: {
      type: String
    },
    items: {
      type: Array
    },
    t: {
      type: String
    },
    paginationTop: {
      type: Boolean,
      default: true
    },
    paginationBottom: {
      type: Boolean,
      default: true
    },
    showError: {
      type: Boolean,
      default: false
    },
    inlineEditing: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    filters: Object,
    limit: {
      type: Number,
      default: 15
    },
    globalLoading: Boolean,
    showTotal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      total: 0,
      page: 1,
      data: [],
      loading: true,
      search: "",
      editedItemIdx: null,
      editedItem: null,
      edited: false
    };
  },
  mounted() {
    if (this.$props.action) {
      this.get();
    } else {
      this.data = this.$props.items;
      this.loading = false;
    }
  },
  computed: {
    fields() {
      const fields = this.$props.headers.map(f => {
        const object = typeof f === "object";

        return {
          ...f,
          key: object ? f.key : f,
          label: this.$t(object ? f.label || kebab(f.key) : kebab(f))
        };
      });

      if (!this.$props.hideActions) {
        fields.push({
          key: "actions",
          label: this.$t("actions")
        });
      }

      return fields;
    },
    editableFields() {
      return this.fields.filter(
        field =>
          !field.noEdit && this.inlineEditing && this.editedItemIdx !== null
      );
    }
  },
  methods: {
    change(k, v, o = null) {
      const index = findIndex(this.data, o => o[k] === v);

      if (index !== -1) {
        if (o === null) {
          this.data = this.data.filter((_, i) => i !== index);
          this.total--;
        } else {
          this.data[index] = o;
        }
      }
    },
    handleEdit(key, value) {
      const editedItemUnchaged = this.items[this.editedItemIdx];
      this.editedItem[key] = value;
      this.edited = !isEqual(this.editedItem, editedItemUnchaged);
    },
    editInput(field) {
      if (field.select) {
        return "b-select";
      } else if (field.textarea) {
        return "b-textarea";
      }
      return "b-input";
    },
    enableEdit(index, item) {
      if (this.editedItemIdx !== null) {
        if (this.editedItemIdx !== index) {
          this.editedItemIdx = index;
          this.editedItem = cloneDeep(item);
        } else {
          this.editedItemIdx = null;
          if (this.edited)
            this.$emit("edited", { item: this.editedItem, index });
          this.editedItem = null;
        }
      } else {
        this.editedItemIdx = index;
        this.editedItem = cloneDeep(item);
      }
      this.edited = false;
    },
    async get({ sortBy, sortDesc } = {}) {
      if (!this.$props.action) return;
      this.loading = true;
      try {
        const res = await this.$store.dispatch(this.action, {
          page: this.page,
          limit: this.limit,
          search: this.search,
          ...this.filters,
          sort_by: sortBy,
          sort_order: sortDesc
        });
        this.data = res.data.data;
        this.total = res.data.total;
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    }
  },
  watch: {
    "$props.items"(items) {
      this.data = items;
    },
    filters: {
      handler() {
        this.get();
      },
      deep: true
    },
    page() {
      this.get();
    }
  }
};
</script>

<style lang="scss">
.data-table {
  .dropdown-toggle::after {
    display: none;
  }

  .inline-editing-input {
    margin: 0;
  }
}
</style>
