<template>
  <div :class="{ filter: true, 'align-end': alignEnd, wide: wide }">
    <span class="label mb-2" v-html="$t(label) || '&nbsp;'" />
    <slot />
  </div>
</template>

<script>
export default {
  name: "adt-filter",
  props: {
    label: String,
    alignEnd: Boolean,
    wide: Boolean
  }
};
</script>

<style lang="scss">
.filter {
  &.wide {
    width: 20rem;
  }
}
</style>
